import React from 'react';
import Modal from 'react-modal';
import './Modal.css';

const GenericModal = ({
  isShown,
  customClassName,
  customOverlayClassName,
  children,
  overlayBackground,
  onRequestClose,
}) => (
  <Modal
    overlayClassName={
      customOverlayClassName
        ? {
            base: customOverlayClassName,
            afterOpen: `${customOverlayClassName}--after-open`,
            beforeClose: `${customOverlayClassName}--before-close`,
          }
        : undefined
    }
    className={
      customClassName
        ? {
            base: customClassName,
            afterOpen: `${customClassName}--after-open`,
            beforeClose: `${customClassName}--before-close`,
          }
        : undefined
    }
    isOpen={isShown}
    style={{
      content: {
        position: 'relative',
        overflow: 'hidden',
        inset: 0,
        backgroundColor: '#ffffff',
        zIndex: 500,
        outline: 'none',
      },
      overlay: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: overlayBackground || '#21292E',
        zIndex: 5,
        outline: 'none',
      },
    }}
    closeTimeoutMS={300}
    onRequestClose={onRequestClose || (() => {})}
  >
    {children}
  </Modal>
);

export default GenericModal;
