import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { Btn } from '../components/StyledComponents';
import axios from 'axios';


const SignupPage = () => {
    let history = useNavigate();

    const [username, setUsername] = useState('');
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const [password, setPassword] = useState('');
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }
    const [email, setEmail] = useState('');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const [displayError, setDisplayError] = useState(false);
    const [validationError, setValidationError] = useState(false);
    useEffect(() => {
        if(confirmPassword!==password){
            setErrMessage("Make sure that password and confirm password fields match")
            setValidationError(true);
        }
    else{
        setValidationError(false)
        setDisplayError(false)
    }
    }, [password, confirmPassword])
 
    const [errMessage, setErrMessage] = useState("");

    const handleSignUpClick = () => {
        axios.post('/auth/register', { username: username, password: password, email: email }).then((res) => {
            if(res.data.message === "ok"){
                history("/login");
            }
        }).catch((err)=>{
            if(err.response.data === "SequelizeUniqueConstraintError"){
                setErrMessage("The username is taken");
                setDisplayError(true);
            }
        })
       }
   
    return (
        <>
            <Nav>&nbsp;
            </Nav>

            <Signup>
                <SignupInfo>
                    <b>Username</b>
                    <input
                        onChange={handleUsernameChange}
                        type="text" placeholder='Enter Username' name="username" required></input>
                </SignupInfo>
                <SignupInfo>
                    <b>Email</b>
                    <input
                        onChange={handleEmailChange}
                        type="text" placeholder='Enter Email' name="email" required></input>
                </SignupInfo>
                <SignupInfo>
                <b>Password</b>   
                    <input
                        onChange={handlePasswordChange}
                        type="password" placeholder="Enter Password" name="password" required></input>
                </SignupInfo>

                <SignupInfo>
                <b>Confirm password</b>   
                    <input
                        onChange={handleConfirmPasswordChange}
                        type="password" placeholder="Confirm Password" name="confirmPassword" required></input>
                </SignupInfo>
            </Signup>
            {
                displayError || validationError ?
                    <ErrMsg>{errMessage}</ErrMsg> :
                    <></>
            }
            <SignupBtn>
                <Btn disabled={validationError || (!username || !password || !confirmPassword)} onClick={handleSignUpClick}>SIGNUP</Btn>
            </SignupBtn>
        </>
    )
}

const Nav = styled.div`
background-color: #15245A;
overflow: hidden;
& a 
{
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 0.2rem 1.6rem;
    text-decoration: none;
    font-size: 1.0rem;
}

& a:hover {
    background-color: #ddd;
    color: black;
}
`

const Signup = styled.div`
    text-align: center;
    justify-content:  center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
`
const SignupInfo = styled.div`
    justify-content: center;
    font-size: 1.5rem;
    display: flex;

    & b {
        text-align: left;
        display: inline-block;
        width: 20rem;
    }

    & input {
        line-height 1.8rem;
        margin-left: 1rem;
    }
`

const SignupBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;

    & :hover {
        background-color:#15245A;
        color: white;
    }

    & :disabled, [disabled] {
        background-color: grey;
        color: white;
    }
`
const ErrMsg = styled.p`
margin-bottom: 4rem;
color: red;
text-align: center;
`
export default SignupPage;