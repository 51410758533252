import React, { useState } from "react";
import { Row, Column } from './StyledComponents';
import styled from "styled-components";
import axios from 'axios';

const GirlForm = ({models, setModels, setIsShown}) => {
    const [model, setModel] = useState({
        campaign: "",
        model_id: "",
        site: "",
        niche: "",
        drive_link: "",
        landing_page: ""
    })

    const handleChange = (key, val) => {
        setModel({ ...model, [key]: val })
    }
    const handleSend = () => {
        axios.post('/girl', { girl: model }).then((res) => {
            setModels([...models, res.data])
            setIsShown(false)
        }).catch((err)=>{alert(err)})
    }

    return (
        <>
            <Column style={{ width: "400px"}}>
                <form action="">

                <Row>
                    <FormInfo>
                        <b>Campaign</b>
                        <Input
                            onChange={(e) => handleChange("campaign", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                <Row>
                    <FormInfo>
                        <b>Model_id</b>
                        <Input
                            onChange={(e) => handleChange("model_id", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                <Row>
                    <FormInfo>
                        <b>Site</b>
                        <Input
                            onChange={(e) => handleChange("site", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                <Row>
                    <FormInfo>
                        <b>Niche</b>
                        <Input
                            onChange={(e) => handleChange("niche", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                <Row>
                    <FormInfo>
                        <b>Drive_link</b>
                        <Input
                            onChange={(e) => handleChange("drive_link", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                <Row>
                    <FormInfo>
                        <b>Landing_page</b>
                        <Input
                            onChange={(e) => handleChange("landing_page", e.target.value)}
                            type="text" required/>
                    </FormInfo>
                </Row>
                </form>

                <SendBtn>
                    <Btn onClick={handleSend}>Add model</Btn>
                </SendBtn>
            </Column>
        </>
    )
}

const Btn = styled.button`
    background-color: #e7e7e7;
    color: black; 
    border: none;
    font-weight: bold;
    padding: 1.2rem 2.2rem;
    margin: 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 3rem;
    cursor: pointer;
`

const SendBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;

    & :hover {
        background-color:#15245A;
        color: white;
    }
`


const FormInfo = styled.div`
    font-size: 1.5rem;
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;

    & b {
        display: inline-block;
        width: 11rem;
    }

    & Input {

        line-height 1.8rem;
        margin-left: 1rem;
    }
`

const Input = styled.input`
    &:invalid:required {
        border: 1px solid red;
        border-radius: .25rem;
    }
`

export default GirlForm;