import axios from "axios";
import React from "react";
import styled from "styled-components";

const ModelActions = ({model, models, setModels, idx}) => {
    console.log(model);
    const editModel = (msg) => { 
        axios.put(`/girl/${model.id}`, {girl: {...model, status: msg}}).then((res)=>{
            const newModels = models.map((m)=>{
                if(m.id === model.id){
                    return res.data
                } else {
                    return m
                }
            })
            setModels(newModels)
        })
    }
    return (
        <div style={{display: "flex", gap: "1rem", justifyContent: "space-evenly"}}>
            <Btn onClick={() => {editModel("APPROVED")} }>Approve</Btn>
            <Btn onClick={() => {editModel("DECLINED")}}>Decline</Btn>
        </div>
    )
};

const Btn = styled.button`
    background-color: #e7e7e7;
    color: black; 
    border: none;
    font-weight: bold;
<<<<<<< HEAD
    padding: .7rem 1rem;
=======
    padding: .5rem 1rem;
>>>>>>> f6cd7122f1045f97d92d790428fb82d4754e7bd4
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 3rem;
    cursor: pointer;
`
export default ModelActions;