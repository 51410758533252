import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import SignupPage from './pages/SignupPage';
import axios from 'axios';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? "/api" : 'http://localhost:3003/api';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute/>}>
            <Route path="/"element={<Dashboard/>} />
          </Route>
          {/* <Route exact path='/register' element={<RegisterPage/>}/> */}
        <Route exact path='/login' element={<LoginPage />} />
        <Route exact path='/signup' element={<SignupPage/>} />
        </Routes>
    </Router>
  );
}

export default App;