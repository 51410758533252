import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useToken from '../hooks/setAuthToken';
import { useFetchUser } from '../hooks/useAuth';

const PrivateRoute = () => {
    const {token} = useToken();


    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return token && token !== 'undefined'? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;