import React from 'react';
import GirlForm from './GirlForm';
import GenericModal from './GenericModal';
import { useState } from 'react';
import styled from "styled-components";

const AddModelModal = ({models, setModels, isShown, setIsShown}) => {
  return (
    <GenericModal
      isShown={isShown}
      customClassName="ScenarioModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <div style={{padding: ".25rem .25rem"}}>
          <Xbtn
          onClick={() => {
            setIsShown(false)
          }}
          
        >X</Xbtn>


        <Txt>Add a new model</Txt>

        <hr style={{ borderTop: "1px solid #000", borderRadius: ".25rem", width: "100%"}}/>

        <GirlForm models={models} setModels={setModels} setIsShown={setIsShown}></GirlForm>

      </div>
    </GenericModal>
  );
};

const Xbtn = styled.button`
  margin-left: 93%;
  background-color: #ddd;
  color: black;
  font-weight: bold;
  cursor: pointer;
  border: none;
`

const Txt = styled.h1`
text-align: center;
`
export default AddModelModal;