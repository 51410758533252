import React, { useState, useEffect } from 'react';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Models from '../components/Models';
import useToken from '../hooks/setAuthToken';
import axios from "axios";
import AddModelModal from '../components/AddModelModal';
import { Column, Row } from '../components/StyledComponents';
import { useFetchUser } from '../hooks/useAuth';

const Dashboard = () => {
    const navigate = useNavigate();
    const [models, setModels] = useState([]);
    const [statistics, setStatistics] = useState("");
    const [isShown, setIsShown] = useState(false);
    const {user, loading} = useFetchUser();
    useEffect(()=>{
        axios.get("/girl/").then((res)=>{
            setModels(res.data)
        })
        axios.get("/statistics/").then((res)=>{
            setStatistics(res.data)
        })
    }, [])
    const {setToken, token} = useToken();
    const handleLogOut = () => {
        localStorage.removeItem("token");
        setToken(undefined);
        window.location.reload(false);
    }
    if(!user.username && !loading) {
        handleLogOut()
        return <Navigate to="/login" />
    }

    const handleClickStatistics = () => {
        axios.post("/statistics/", {statistics}).then((res)=>{ 
            if(res.data !== "ok")
                alert("Could not save.")
        })
    }
    
    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <Nav>           
            <a href="#modelReg">MODEL REGISTRATION</a>
                <a href="#overview">OVERVIEW</a>
                <h1>Hello {user.username}</h1>
                <h2 onClick={handleLogOut}>Log out</h2>
            </Nav>
            <Row style={{justifyContent: "center"}}>
            <Title>Dashboard</Title>
            </Row>
            <Row style={{justifyContent: "center"}}>

            <Column >
                <Row style={{justifyContent: "center"}}>
                <StatisticsTitle>Statistics</StatisticsTitle>
                </Row>
                <Row>
                    <Textarea resize="vertical" value={statistics} onChange={(e)=>setStatistics(e.target.value)}></Textarea>
                    <button onClick={handleClickStatistics}>Save</button>
                </Row>
                <Row>
                    <AddNew>
                        <Btn onClick={()=> {setIsShown(true)}}>Add new model</Btn>
                    </AddNew>
                </Row>
            </Column>
            </Row>
            <Row style={{justifyContent: "center"}}>
                <Models models={models} setModels={setModels}></Models>
            </Row>
            <AddModelModal models={models} setModels={setModels} isShown={isShown} setIsShown={setIsShown} />

            <Footer>
                <Icons>
                    <Social>
                        <a href='#aaa'>
                            <i class="fa fa-telegram" href=' ' style={{ fontSize: "3rem", color: " #2AABEE", }}></i>
                        </a>
                    </Social>
                    
                    <Social>
                        <a href='#qqqq'>
                            <i class="fa fa-skype" style={{ fontSize: "3.3rem", color: " #2AABEE" }}></i>
                        </a>
                    </Social>
                </Icons>
            </Footer>
        </>
    )
}

const Social = styled.div`
padding: 2%;
background-color: transparent;
cursor: pointer;
`

const Icons = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
position: fixed;
left: 0;
bottom: 0;
width: 100%;
background-color: white;
color: white;
text-align: center;
height: 7%;
`

const Footer = styled.div`
text-align: center;
padding: 2rem;
background-color: white;
color: white;
`

const AddNew = styled.div`
& :hover {
    background-color:#15245A;
    color: white;
}
`

const Textarea = styled.textarea`
resize: vertical;
`

const StatisticsTitle = styled.h5`
    margin-top: .25rem;
    margin-bottom: 0;
`

const Btn = styled.button`
    background-color: #e7e7e7;
    color: black; 
    border: none;
    font-weight: bold;
    padding: 1.2rem 2.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 3rem;
    cursor: pointer;
`
const Nav = styled.div`
background-color: #15245A;
overflow: hidden;
& a 
{
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 0.2rem 1.6rem;
    text-decoration: none;
    font-size: 1.0rem;
}

& h1 {
    float: right;
    color: #f2f2f2;
    text-align: center;
    padding: 0.2rem 1.6rem;
    text-decoration: none;
    font-size: 1.0rem;
    margin: 0;
}
& h2 {
    float: right;
    color: #f2f2f2;
    text-align: center;
    padding: 0.2rem 1.6rem;
    text-decoration: none;
    font-size: 1.0rem;
    margin: 0;
}
& h2:hover {
    background-color: #ddd;
    color: black;
}

& a:hover {
    background-color: #ddd;
    color: black;
}
`

const Title = styled.h1`

`

export default Dashboard;