import axios from "axios";
import { useState, useEffect } from "react";

export const useFetchUser = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data: response } = await axios.get('/auth/me');
          setUser(response.user);
        } catch (error) {
          console.error(error)
        }
        setLoading(false);
      };
  
      fetchData();
    }, []);
  
    return {
      user,
      loading,
    };
  };