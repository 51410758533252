import styled from "styled-components";

const Btn = styled.button`
    background-color: #e7e7e7;
    color: black; 
    border: none;
    font-weight: bold;
    padding: 1.2rem 2.2rem;
    margin: 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 3rem;
    cursor: pointer;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
    Btn,
    Row,
    Column,
};