import { useState } from 'react';
import axios from 'axios';

export default function useToken() {
  
  const getToken = () => {
    const token = localStorage.getItem('token');
    return token
  };

  const [token, setToken] = useState(getToken());
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const saveToken = userToken => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  };

  return {
    setToken: saveToken,
    token: token,
  }
}