import React, { useState } from "react";
import { Row, Column } from './StyledComponents';
import styled from "styled-components";
import ModelCard from "./ModelCard";
import { useFetchUser } from "../hooks/useAuth";


const Models = ({models, setModels}) => {
    const {user} = useFetchUser();

    const sortModels = (m1, m2) => {
        if(user.role === 'admin'){
            return m1.status !== "PENDING" ? 1 : -1;
        } else {
            return m1.status === "PENDING" ? 1 : -1;
        }
    }

    return (
        <>
            <Column style={{display: "flex", flexDirection: "row", gap: "3rem", marginLeft: "1rem", flexWrap: "wrap", justifyContent: "center"}}>
                {
                    models.sort(sortModels).map((model, idx)=>{
                        return (
                        <Row key={model.id}>
                            <ModelCard model={model} models={models} setModels={setModels} idx={idx}></ModelCard>
                            {/* <ModelInfo>
                                <b>{model.model_id}</b>
                                
                            </ModelInfo> */}
                        </Row>
                        )
                    })
                }
            </Column>
        </>  
    )
}


const ModelInfo = styled.div`
    font-size: 1.5rem;
    padding: 1rem;
    display: flex;

    & b {
        text-align: left;
        margin-top: .5rem;
        display: inline-block;
        width: 11rem;
    }
`

export default Models;