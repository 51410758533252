import React, {useState} from 'react';
import styled from 'styled-components';
import { Navigate, useNavigate } from "react-router-dom";
import { Btn } from '../components/StyledComponents';
import axios from 'axios';
import useToken from '../hooks/setAuthToken';

const LoginPage = () => {
    let history = useNavigate();
    const { token, setToken } = useToken();

    const [username, setUsername] = useState('');
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const [password, setPassword] = useState('');
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    const handleSignUpClick = () => {
        history("/signup");
    }
    const handleLogInClick = () => {
        axios.post('/auth/login', { username: username, password: password }).then((res) => {
            //get token from response
            const token  =  res.data.token;
            //set token to axios common header
            setToken(token);
            history("/");
        })
    }
    if(token && token !== 'undefined') return <Navigate to="/" />
    return (
        <>
            <Nav>&nbsp;
            </Nav>

            <Login>
                <LoginInfo>
                    <b>Username</b>
                    <input
                        onChange={handleUsernameChange}
                        type="text" placeholder='Enter Username' name="username" required></input>
                </LoginInfo>
                <LoginInfo>
                <b>Password</b>   
                    <input
                        onChange={handlePasswordChange}
                        type="password" placeholder="Enter Password" name="password" required></input>
                </LoginInfo>
            </Login>
            <LoginBtn>
                <Btn onClick={handleLogInClick}>LOGIN</Btn>
                <Btn onClick={handleSignUpClick}>SIGNUP</Btn>
            </LoginBtn>
        </>
    )
}

const Nav = styled.div`
background-color: #15245A;
overflow: hidden;
& a 
{
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 0.2rem 1.6rem;
    text-decoration: none;
    font-size: 1.0rem;
}

& a:hover {
    background-color: #ddd;
    color: black;
}
`

const Login = styled.div`
    text-align: center;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 2rem;
    `
const LoginInfo = styled.div`
    font-size: 1.5rem;
    padding: 1rem;
    
    & b {
        margin-right: 1rem;
    }

    & input {
        line-height 1.8rem;
    }
`

const LoginBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;

    & :hover {
        background-color:#15245A;
        color: white;
    }
`

export default LoginPage;