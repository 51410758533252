import React from "react";
import { Row, Column, } from "./StyledComponents";
import styled from "styled-components";
import { useFetchUser } from "../hooks/useAuth";
import ModelActions from "./ModelActions";
import axios from "axios";

const getBg = (status) => {
    switch(status){
        case 'PENDING': return "gray";
        case 'APPROVED': return "#1FF134";
        case 'DECLINED': return "#F11F1F";
    }
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const parseDate = (date) => {
    return `
        ${date.getHours()}:${date.getMinutes()} ${date.getDay()+1}/${monthNames[date.getMonth()]}/${date.getFullYear()}
    `
}

const ModelCard = ({model, models, setModels, idx}) => {
    const {user} = useFetchUser();

    const handleDelete = () => {
        axios.delete(`/girl/${model.id}`).then((res)=>{
            setModels(models.filter((m)=>m.id !== model.id))
        })
    }

    return (
        <Column style={{
            border: "1px solid black",
            borderRadius: ".25rem",
            display: "flex",
            gap: ".5rem",
            justifyContent: "center",
            padding: "1rem",
            width: "250px",
        }}>
            <Row>
            Campaign: {model.campaign} 
            </Row>
            <Row>
            Name: {model.model_id} 
            </Row>
            <Row>
            Website: 
            <a style={{marginLeft: "1ch", width: "100px", whiteSpace: "nowrap", overflow:"hidden", textOverflow: "ellipsis"}}
            href={model.site}>
                {model.site}
                </a>  
            </Row>
            <Row>
            Niche: {model.niche} 
            </Row>
            <Row>
            Drive link: 
                <a style={{marginLeft: "1ch", width: "100px", whiteSpace: "nowrap", overflow:"hidden", textOverflow: "ellipsis"}}
                href={model.drive_link}>
                {model.drive_link}
                </a>  
            </Row>
            <Row>
            Landing page: 
                <a style={{marginLeft: "1ch", width: "100px", whiteSpace: "nowrap", overflow:"hidden", textOverflow: "ellipsis"}}
                href={model.landing_page}>
                    {model.landing_page}
                </a> 
            </Row>
            <Row>
                Date added: {parseDate(new Date(model.createdAt))}
            </Row>
            <hr style={{ borderTop: "2px solid #bbb", borderRadius: ".25rem", width: "100%"}}/>
            
            <Row style={{justifyContent: "center"}}>
            {
                user.role === 'admin' ?
                    (
                        model.status === "PENDING" ?
                        <ModelActions model={model} models={models} setModels={setModels} idx={idx}/> :
                        <Approved style={{backgroundColor: getBg(model.status)}}>{model.status}</Approved>
                    ) :
                    <Approved style={{backgroundColor: getBg(model.status)}}>{model.status}</Approved>

            }
            </Row>
            <Row style={{justifyContent: "center"}}>
            <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
            </Row>
        </Column>
    )
}

const Approved = styled.div`
justify-content: center;
text-align: center;
padding: .7rem;
line-height 1.8rem;
`
const DeleteBtn = styled.button`
justify-content: center;
text-align: center;
padding: .5rem 1rem;
text-decoration: none;
border: none;
background-color: #ddd;
font-weight: bold;
font-size: 1rem;
cursor: pointer;
`

export default ModelCard;